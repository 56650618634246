import React from 'react';
import { Table } from '../../../components/table';
import { Row, Text } from '@unthinkable/react-core-components';
import { Button } from '../../../components/button';

/**
 * Header Component
 * This component serves as the header for the WorkoutPlanListView.
 * It contains a title and a button to add a new workout plan.
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} - The rendered Header component.
 */
export const Header = props => {
  const { navigation } = props || {};
  return (
    <Row
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 5,
      }}>
      <Text>Workout Plans</Text>
      <Button
        text="Add"
        onPress={() => {
          navigation.navigate('add-workout-plan');
        }}
      />
    </Row>
  );
};

/**
 * WorkoutPlanList Component
 * This component renders a table listing workout plans fetched from the API.
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} - The rendered WorkoutPlanList component.
 */
const WorkoutPlanList = props => {
  return (
    <Table
      api="/fitness-tracking/workout-plan"
      eventSourceId={['WorkoutPlanList']}
      renderHeader={() => {
        return <Header {...props} />;
      }}
      columns={[
        {
          header: 'Plan Name',
          type: 'text',
          field: 'plan_name',
        },
        {
          header: 'Trainer Name',
          type: 'text',
          field: 'trainer_name',
        },
        {
          header: 'Number of Exercises',
          type: 'number',
          field: 'number_of_exercises',
        },
        {
          header: 'Description',
          type: 'text',
          field: 'description',
        },
      ]}
    />
  );
};

export default WorkoutPlanList;
