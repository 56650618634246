import React, {useContext} from 'react';
import {useToast} from '@unthinkable/react-toast';

import {HttpAuthService} from '../../services';

const AppStateContext = React.createContext();

const useAppState = props => {
  const toast = useToast();

  const fetch = async ({uri, props}, encryption) => {
    try {
      const result = await HttpAuthService.get(uri, {...props, encryption});
      return result;
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  const value = {fetch};

  return value;
};

export const AppStateProvider = ({children, ...props}) => {
  let appState = useAppState({
    status: 'initializing',
    ...props,
  });
  return (
    <AppStateContext.Provider value={appState}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppStateContext = () => {
  return useContext(AppStateContext);
};
