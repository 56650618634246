// Auth.service.js
import HttpAuth from './HttpAuthService';

export default class AuthService {
  // Function to handle user login
  static async login(credentials) {
    const response = await HttpAuth.post('/auth/login', credentials);
    return response?.data;
  }

  // Function to handle user registration
  static async register(userData) {
    const response = await HttpAuth.post('/auth/register', userData);
    return response?.data;
  }

  // Function to refresh the access token
  static async verifyToken() {
    const response = await HttpAuth.get('/auth/verify-token');
    return response?.data;
  }

  // Function to handle user logout
  static async logout() {
    // Perform API call to logout endpoint if necessary
    const response = await HttpAuth.post('/auth/logout');
    return response;
  }

  // Function to change user password
  static async changePassword(passwordData) {
    const response = await HttpAuth.put(
      '/auth/change-password',
      passwordData,
    );
    return response?.data;
  }

  // Function to initiate a forgot password request
  static async forgotPassword(userData) {
    const response = await HttpAuth.post('/auth/forgot-password', userData);
    return response;
  }

  // Function to reset the password using a token received via email
  static async resetPassword(userData) {
    const url = `/auth/reset-password`;
    const response = await HttpAuth.post(url, userData);
    return response;
  }

  // Function to resend the email verification link
  static async resendVerificationEmail(email) {
    const response = await HttpAuth.post('/auth/resend-verification-email', {
      email,
    });
    return response;
  }

  // Function to verify an email address using a token sent via email
  static async verifyEmail(token) {
    const url = `/auth/verify-email?token=${encodeURIComponent(token)}`;
    const response = await HttpAuth.post(url);
    return response;
  }
}
