import MainRoutes from '../screens/Routes';

const leftMenuRoutes = [...MainRoutes.leftMenu];
const stackRoutes = [];
const ModalRoutes = [];

export default {
  leftMenu: leftMenuRoutes,
  stack: stackRoutes,
  modal: ModalRoutes,
};
