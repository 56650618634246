import React from 'react';
import {AuthProvider} from './auth/AuthProvider';
import {LocaleProvider} from './locale/LocaleProvider';
import {ToastProvider} from './toast/ToastProvider';
import {EventHandlerProvider} from './eventHandler/EventHandlerProvider';
import {MediaQueryProvider} from './mediaQuery/MediaQueryProvider';
import {ThemeProvider} from './theme/ThemeProvider';
import {AppStateProvider} from './app/AppState';

// Import other created Providers and add them here
const providers = [
  AuthProvider,
  LocaleProvider,
  EventHandlerProvider,
  MediaQueryProvider,
  ThemeProvider,
  ToastProvider,
  AppStateProvider,
];

const combineProviders = components => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      const ProviderWrapper = props => {
        const {children} = props || {};
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
      return ProviderWrapper;
    },
    ({children}) => <>{children}</>,
  );
};

// Combining multiple providers to single provider - this will be wrapped around App.js
export default combineProviders(providers);
